<template>
  <v-app>
    <nav-app-bar />
    <nav-app-drawer />
    <v-main style="background: #eee">
      <show-info />
      <confirm-dialog ref="confirm" />
      <div>
        <router-view />
      </div>
      <portal-target name="v-main" multiple />
    </v-main>
    <confirm-dialog ref="confirmDialogGlobal"></confirm-dialog>
  </v-app>
</template>

<script>
//import { messaging, getToken } from "@/libs/firebase";
import { notificationBus } from "@/libs/notificationBus";
export default {
  data() {
    return {};
  },
  created() {
    this.webSocket();
  },
  mounted() {
    // Слушать уведомления о новой версии
    notificationBus.$on("newVersionAvailable", this.handleNewVersion);
  },
  beforeDestroy() {
    notificationBus.$off("newVersionAvailable", this.handleNewVersion);
  },
  computed: {},
  methods: {
    async handleNewVersion({ registration }) {
      let ok = await this.$refs.confirmDialogGlobal.show({
        title: "Новая версия",
        message: [`Мы только что обновили версию приложения!`, "Чтобы получить обновления, нажмите на кнопку ниже (страница перезагрузится)"],
        okButton: "Обновить",
        cancelButton: "Отмена",
      });
      if (ok) {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      }
    },
    webSocket() {
      let usertoken = localStorage.getItem("user-token");
      let socketUrl = this.$root.config.websocetUrl;
      if ("WebSocket" in window) {
        this.$root.websocket = new WebSocket(socketUrl);
        let timer;
        this.$root.websocket.onopen = () => {
          this.$root.websocket.send(JSON.stringify({ e: "auth", d: usertoken }));
          this.$root.websocketWorking = true;

          timer = setInterval(() => {
            let d = { e: "h" };
            this.$root.websocket.send(JSON.stringify(d));
          }, 30000);
          /*           getToken(messaging, {
            vapidKey: "BLA25RvLs8MFMjceR6QjbqBGi5mmDQ_1c6WMjNqFkyK5Epacyaylit1eSujJ273q6uFH2ONTljFeEWw9tULnhM8",
          })
            .then((token) => {
              if (token) {
                this.$axios
                  .post("/users/users_firebase", { token })
                  .then((res) => {
                    //console.log("users_firebase ");
                  })
                  .catch((err) => {
                    console.log("users_firebase error. ", err);
                    // ...
                  });

                // window.localStorage.setItem('FCM token', currentToken)
                // Send the token to your server and update the UI if necessary
                // ...
              } else {
                // Show permission request UI
                console.log("No registration token available. Request permission to generate one.");
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            }); */
        };
        this.$root.websocket.onmessage = evt => {
          //if (evt.data != '{"r":"ok"}') console.log("websocket.onmessage", evt.data, evt);
          this.$root.$emit("websocket", JSON.parse(evt.data));
        };
        this.$root.websocket.onclose = () => {
          console.log("Connection is closed...");
          //   this.$root.$emit("show-info", { type: "error", text: "Ошибка соединения с сервером" });
          this.$root.websocketWorking = false;
          clearInterval(timer);
          setTimeout(this.webSocket, 1000);
        };
        this.$root.websocket.onerror = err => {
          console.error("Socket encountered error: ", err.message, "Closing socket");
          this.$root.websocket.close();
        };
      } else {
        console.log("WebSocket NOT supported by your Browser!");
      }
    },
  },
};
</script>
